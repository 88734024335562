<template>
    <div class="admin-menu-background">
        <div class="admin-menu-header">
            Panell d'administració  
        </div>
        <div class="admin-option" v-bind:class="{ 'admin-option-selected': currentAdminTab==1 }" @click="changeTab(1)">
            <img :src="require('@/assets/img/iconuser.png')" alt="Logo Arrels" class="admin-menu-icon">
            Usuaris
        </div>
        <div class="admin-option" v-bind:class="{ 'admin-option-selected': currentAdminTab==2 }" @click="changeTab(2)">
            <img :src="require('@/assets/img/iconnew.png')" alt="Logo Arrels" class="admin-menu-icon">
            Nou usuari
        </div>
        <div class="admin-option" v-bind:class="{ 'admin-option-selected': currentAdminTab==3 }" @click="changeTab(3)">
            <img :src="require('@/assets/img/iconfinca.png')" alt="Logo Arrels" class="admin-menu-icon">
            Finques
        </div>
        <div class="admin-option" v-bind:class="{ 'admin-option-selected': currentAdminTab==4 }" @click="changeTab(4)">
            <img :src="require('@/assets/img/iconnew.png')" alt="Logo Arrels" class="admin-menu-icon">
            Nova finca
        </div>
    </div>
</template>
<script>
export default {
    name: 'Adminmenu',
    computed: {
        currentAdminTab() {
            return this.$store.state.admin.currentTab;
        },
    },
    methods: {
        changeTab(tab) {
            this.$store.dispatch('setAdminTab', tab)          
        },
    }
}
</script>
<style scoped>
.admin-menu-header {
    padding: 20px;
    background-color: #6c7275;
    color: rgb(212, 217, 226);
    font-weight: 600;
    border-bottom: 2px solid rgb(95, 97, 102);
    box-shadow: 0px 3px 5px 0px rgba(120,120,120,0.4);
    z-index: 50;
    position: relative;
}
.admin-menu-background {
    background-color: #d9d9d9;
    border-right: 2px solid rgb(72, 74, 78)
}
.admin-option {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid gray;
    text-align: initial;
    cursor: pointer;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.admin-option:hover {
    background-color:#FE9C54;
    -moz-box-shadow:    inset 2px 2px 6px #a16741;
    -webkit-box-shadow: inset 2px 2px 6px #a16741;
    box-shadow:         inset 2px 2px 6px #a16741;
}
.admin-option-selected {
    background-color:#cecaca;
    -moz-box-shadow:    inset 2px 2px 6px #7c8388;
    -webkit-box-shadow: inset 2px 2px 6px #7c8388;
    box-shadow:         inset 2px 2px 6px #7c8388;
    /*background-color:#ffd4b6;
    border: 3px solid #FE9C54;*/
}
.admin-menu-icon {
    width: 20px;
    margin-right: 10px;
}
</style>