<template>
  <div class="home">
    <Header></Header>
    <div class="home-master-container">
      <div class="home-master-container-dark-overlay"></div>
      <div class="menu-bar" style="background: #fbfbfb; width: 100px; left: 0; height: calc(100vh - 50px); box-shadow: 2px 0px 5px 2px rgba(0,0,0,0.2)"></div>

      <div class="container home-container" ref="homeContainer">
            <div class="finca-title" ref="fincaTitle">
              <span v-if="selectedFinca">{{ selectedFinca.fincaname }}</span>
            </div>

            <div class="row h-50">
              <div class="col-sm block1 align-self-end section" v-on:click="loadSection('tecnic')">
                <!--Llibre tècnic-->
                <div class="block-tecnic"></div>
              </div>
              <div class="col-sm block2 align-self-end section" v-on:click="loadSection('records')">
                <!--Llibre dels records-->
                <div class="block-records"></div>
              </div>
            </div>

            <div class="row h-50">
              <div class="col-sm block3 align-self-top">
                Documents digitals
              </div>
              <div class="col-sm block4 align-self-top">
                Les meves arrels
              </div>
            </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import { get } from '@/services/api.js'

export default { 
  name: 'Home',
  components: {
    Header
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    selectedFinca() {
      return this.$store.state.finques.selectedFinca
    },
    userFinques() {
      return this.$store.state.finques.userFinques
    }
  },
  data() {
    return {

    }
  },
  watch: {
    /*'selectedFinca': function () {
      this.setTitleWidth()
    }*/
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    this.$store.dispatch('setAdminPanel', false)
    this.getFinques()
    this.setTitleWidth()
    //window.addEventListener('resize', this.setTitleWidth());
    //this.$refs.homeContainer.addEventListener('resize', this.setTitleWidth())  
    document.addEventListener.call(window, "resize", event => {
      this.setTitleWidth()
    })
  },
  methods: {
    //getUser(){
    //  get('users/' + this.currentUser.user.id)
    //},
    getFinques() {
      get('userfinques/user/' + this.currentUser.user.id).then( response =>{
        if(response){
          this.$store.dispatch('changeUserFinques', response.finques)
          if(!this.selectedFinca){
            this.$store.dispatch('changeSelectedFinca', response.finques[0])
          }
        }       
      })
    },
    loadSection(section) {
      if(section == 'tecnic') this.$router.push('/tecnic')
      if(section == 'records') this.$router.push('/records')
    },
    setTitleWidth() {
      let homeWidth = this.$refs.homeContainer.clientWidth
      console.log(homeWidth)
      if(this.$refs.fincaTitle) {
        this.$refs.fincaTitle.style.width = homeWidth + 'px'
      }
    }
  }
}
</script>

<style scoped>
.section {
  cursor: pointer;
}
</style>
