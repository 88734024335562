<template>
    <div class="">
        <div class="admin-sections-header-text">Finques de {{ userFinques.name }}</div>
            <table class="custom-table">
                <tr>
                    <th>Codi finca</th>
                    <th>Nom Finca</th>
                    <th class="action-column"></th>
                </tr>
                <tr v-for="finca in finques" :key="finca.id">
                    <td>{{ finca.fincaid }}</td>
                    <td>{{ finca.fincaname }}</td>
                    <td class="action-column"><img :src="require('@/assets/img/icondelete.png')" alt="delete finca" class="table-icon" @click="unbind(finca.id)"></td>
                </tr>
            </table>
            <div class="separator"></div>
            <label for="selectyedFinca">Afegir una finca</label>
            <div class="row">
                <div class="col-sm-10">
                    <select v-model="selectedFinca" name="selectedFinca">
                        <option v-for="finca in allFinques" :key="finca.id" :value="finca.id"> {{ finca.fincaid }} - {{ finca.fincaname }}</option>
                    </select>
                </div>
                <div class="col-sm-2">
                    <div class="custom-button button-gray" @click="bind()" style="padding: 5px; width: 40px; height: 40px;">
                        <img :src="require('@/assets/img/iconnew.png')" alt="add finca" class="table-icon" style="width: 10px;">
                    </div>
                </div>
            </div>
            <div class="separator"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Tancar</div>
            </div>
    </div>
    
</template>
<script>
import { get, post } from '@/services/api.js'

export default {
    name: 'AdminUserFinques',
    props: {
        userFinques: ''
    },        
    data() {
        return {
            finques: [],
            allFinques: [],
            selectedFinca: ''
        }
    },
    mounted() {
        this.getUserFinques()
        this.getAllFinques()
    },
    methods: {
        getUserFinques(userid, fincaid){
            let url = 'userfinques/user/' + this.userFinques.id
            get(url)
            .then(response => {
                this.finques = response.finques
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        getAllFinques() {
            let url = 'finques'
            get(url)
            .then(response => {
                this.allFinques = response.finques
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        bind(fincaid) {
            let url = 'userfinques/' + this.userFinques.id + '/' + this.selectedFinca
            post(url, null)
            .then(response => {
                this.getUserFinques()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        unbind(fincaid) {
            let url = 'userfinques/' + this.userFinques.id + '/' + fincaid + '/delete'
            post(url, null)
            .then(response => {
                this.getUserFinques()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeModal() {
            this.$parent.closeUserFinques()
        },
    }
}
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #cccccc;
    }
</style>