import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://arrelsapi.geo-memorial.cat/api/';

class AuthService {
  login(name,password) {
    const user_data = {name, password}
    return axios
        .post(API_URL + 'auth/login', user_data)
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        });
  }

  logout() {
    return axios
        .post(API_URL + 'auth/logout', null, { headers: authHeader() })
        .then(response => {
          localStorage.removeItem('user');
          return response.data;
        });
  }
}

export default new AuthService();