<template>
    <div class="">
        <div class="admin-sections-header-text">Usuaris</div>

        <table class="custom-table">
            <tr>
                <th>Codi</th>
                <th>Nom</th>
                <th>Email</th>
                <th class="action-column">Finques</th>
                <th class="action-column">Editar</th>
                <th class="action-column">Esborrar</th>
            </tr>
            <tr v-for="user in users" :key="user.id">
                <td>{{ user.userid }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td class="action-column"><img :src="require('@/assets/img/iconfincaedit.png')" alt="User's fincas" class="table-icon" @click="userFinquesModal(user.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/iconedit.png')" alt="Edit user" class="table-icon" @click="editUserModal(user.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icondelete.png')" alt="delete user" class="table-icon" @click="confirmDeleteModal(user.id)"></td>
            </tr>
        </table>

        <!-- Finques User Modal -->
        <div class="admin-modal" v-if="userFinques"><AdminUserFinques :userFinques="userFinques"></AdminUserFinques></div>

        <!-- Edit User Modal -->
        <div class="admin-modal" v-if="userToEdit"><AdminNewUser :editUser="userToEdit"></AdminNewUser></div>

        <!-- Delete User Modal -->
        <div class="admin-modal" v-if="userToDelete">
            <div class="admin-sections-header-text">Esborrar usuari</div>
            <div v-if="!userDeletedOk">
                Esborrar aquest usuari?
                <div class="buttons-container">
                    <div class="custom-button button-gray" @click="closeDeleteUser()">Cancel·lar</div>
                    <div class="custom-button button-green" @click="deleteUser()">Esborrar usuari</div>
                </div>
            </div>
            <div v-else>
                Usuari esborrat amb exit
                <div class="buttons-container">
                    <div class="custom-button button-green" @click="closeDeleteUser()">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, post } from '@/services/api.js'
import AdminNewUser from '@/components/AdminNewUser.vue'
import AdminUserFinques from '@/components/AdminUserFinques.vue'

export default {
    name: 'Adminusers',
    components: {
        AdminNewUser,
        AdminUserFinques
    },
    data() {
      return {
        users: [],
        userToEdit: null,
        userToDelete: null,
        userFinques: null,
        userDeletedOk: false,
      }
    },
    mounted () {
        this.getUsers()
    },
    methods: {
        getUsers() {
            get('users')
            .then(response => {
                this.users = response.users
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        editUserModal(userId) {
            let url = 'users/' + userId
            get(url)
            .then(response => {
                this.userToEdit = response.user[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeEditUser(){
            this.userToEdit = null
            this.getUsers()
        },
        confirmDeleteModal(userId){
            this.userToDelete = userId
        },
        deleteUser(){
            let url = 'users/' + this.userToDelete + '/delete'
            post(url)
            .then(response => {
                this.userDeletedOk = true
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeDeleteUser() {
            this.userToDelete = null
            this.userDeletedOk = false
            this.getUsers()
        },
        userFinquesModal(userId) {
            let url = 'users/' + userId
            get(url)
            .then(response => {
                this.userFinques = response.user[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeUserFinques() {
            this.userFinques = null
        },
    }
}
</script>
<style scoped>

</style>