<template>
    <div class="">
        <div class="admin-sections-header-text">Documents de {{ finca.fincaname }}</div>

            <!-- Llibre Tècnic -->
            <div class="separator"></div>
            <div class="row">
                <div class="col-sm-10">
  
                        <label>Llibre tècnic</label>
                        <br>
                        <input
                            type="file"
                            ref="fileTecnicInput"
                            style="display: none"
                            accept="application/pdf"
                            @change="onFilePicked"
                        />
                        <div class="custom-button button-gray-green" @click="onPickFile('tecnic')" style="margin: 0; padding: 15px;">
                            <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 15px; margin-right: 8px;">Sel·leccionar axiu
                        </div>             
                </div>
                <div class="col-sm-2 align-self-center">
                    <img v-if="tecnic == true" :src="require('@/assets/img/icon-file-pdf.png')" alt="add user" class="" style="width: 50px;">
                    <img v-else :src="require('@/assets/img/icon-file-pdf-no.png')" alt="add user" class="" style="width: 50px;">
                </div>
            </div>

            <!-- Llibre Records -->
            <div class="separator"></div>
            <div class="row">
                <div class="col-sm-10">
  
                        <label>Llibre dels records</label>
                        <br>
                        <input
                            type="file"
                            ref="fileRecordsInput"
                            style="display: none"
                            accept="application/pdf"
                            @change="onFilePicked"
                        />
                        <div class="custom-button button-gray-green" @click="onPickFile('records')" style="margin: 0; padding: 15px;">
                            <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 15px; margin-right: 8px;">Sel·leccionar axiu
                        </div>             
                </div>
                <div class="col-sm-2 align-self-center">
                    <img v-if="records == true" :src="require('@/assets/img/icon-file-pdf.png')" alt="add user" class="" style="width: 50px;">
                    <img v-else :src="require('@/assets/img/icon-file-pdf-no.png')" alt="add user" class="" style="width: 50px;">
                </div>
            </div>

            <div class="separator"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Tancar</div>
            </div>
    </div>
    
</template>
<script>
import { get, post, uploadFile } from '@/services/api.js'

export default {
    name: 'AdminFincaFiles',
    props: {
        finca: ''
    },        
    data() {
        return {
            fileTypeToUpload: '',
            tecnic: '',
            records: '',
            documents: [],
            mapFile: ''
        }
    },
    mounted() {
        this.getFilesStatus()
    },
    methods: {
         getFilesStatus() {
            let url = 'finques/files/' + this.finca.id
            get(url)
            .then(response => {
                this.tecnic = response.isTecnic
                this.records = response.isRecords
                //this.documents = response.data.documents
                //this.mapFile = response.data.mapFile
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }       
            }) 
        },
        onPickFile (type) {
            if(type == 'tecnic'){
                this.fileTypeToUpload = 'tecnic'
                this.$refs.fileTecnicInput.click()
            }
            else if(type == 'records'){
                this.fileTypeToUpload = 'records'
                this.$refs.fileRecordsInput.click()
            }
        },
        onFilePicked (event) {
            const files = event.target.files
            let filename = files[0].name
            let url
            if(this.fileTypeToUpload == 'tecnic'){
                url = 'finques/files/' + this.finca.id + '/tecnic'
            }
            else if(this.fileTypeToUpload == 'records'){
                url = 'finques/files/' + this.finca.id + '/records'
            }
            uploadFile(url, files[0])
            .then(response => {
                this.getFilesStatus()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
            

            /*let url = 'finques/files/' + this.finca.id + '/' + this.fincaUsers.id + '/delete'
            post(url, null)
            .then(response => {
                this.getFincaUsers()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })*/
            /*const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]*/
        },
        unbind(userid) {
            let url = 'userfinques/' + userid + '/' + this.fincaUsers.id + '/delete'
            post(url, null)
            .then(response => {
                this.getFincaUsers()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeModal() {
            this.$parent.closeFincaFiles()
        },
    }
}
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #cccccc;
    }
</style>