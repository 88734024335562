<template>
  <div class="about">
    <Header></Header>
    <h1>Login</h1>
    Usuari
    <input type="text" v-model="user.name">
    <br>
    Contrasenya
    <input type="password" v-model="user.password">
    <br>
    <p v-if="error" class="error">Has introducido mal el email o la contraseña.</p>
    <input class="form-submit" type="submit" value="Login" @click="SubmitLogin()">
  </div>
</template>
<script>
import User from '../models/user';
import Header from '@/components/Header.vue'

export default {
  name: 'Login',
  components: {
    Header
  },
  data() {
    return {
      user: new User('', ''),
      //user: '',
      //password: '',

      error: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    SubmitLogin() {
      if (this.user.name && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$router.push('/');
          },
          error => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    }
  }
}
</script>
