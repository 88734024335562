<template>
  <div class="home">
    <Header></Header>
    <div class="home-master-container">
      <div class="menu-bar" style="background: #fbfbfb; width: 100px; left: 0; height: calc(100vh - 80px); box-shadow: 2px 0px 5px 2px rgba(0,0,0,0.2)"></div>
      <div style="width: 100%;">
        <!-------- LOADER --------->
        <div class="loader-pdf" v-bind:class="{ 'loading': loading==true }">
          <div class="loader-text" v-bind:class="{ 'spinner-loading': loading==true }">Carregant document...</div>
          <div class="lds-ellipsis" v-bind:class="{ 'spinner-loading': loading==true }"><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="loader-pdf-wall" v-bind:class="{ 'loader-pdf-wall-loading': loading==true }"></div>

        <!-------- DOCUMENT --------->
        <!--<div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">-->
          <vue-pdf-app v-if="pdf_url" :pdf="pdf_url" :config="pdf_config" @pages-rendered="pagesRendered"></vue-pdf-app>
        <!--</div>-->
      </div>
    </div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import { get, getPdf } from '@/services/api.js'
import VuePdfApp from "vue-pdf-app"
//import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css"

export default { 
  name: 'Records',
  components: {
    Header,
    VuePdfApp
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    selectedFinca() {
      return this.$store.state.finques.selectedFinca
    },
  },
  data() {
    return {
      pdf_url: null,
      loading: true,
      pdf_config: {
        toolbar: {
          toolbarViewerRight: false,
        },
        secondaryToolbar: false   
      }
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    if (this.selectedFinca){
      this.loadPDF()
    }
    else {
      this.$router.push('/')
    }
    
  },
  methods: {
    loadPDF() {
      getPdf('finques/files/get-file/'+ this.selectedFinca.id +'/records')
      .then(response => {
        this.pdf_url = response
        this.loaded()
      })
      .catch(error => {
        console.log("ERROR")
        this.loaded()
        this.$router.push('/')
      })
    },
    loaded() {
      this.loading = false
    },
    pagesRendered(pdfApp) {
      // Sets 100% height
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-height"))
    }
  }
}
</script>
<style scoped>
.loader-pdf, .loader-pdf-wall{
  background-color: rgba(255,255,255,0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}
.loader-pdf {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading {
  background-color: rgba(255,255,255,0.8);
}
.loader-text {
  font-size: 1.2rem;
  color: #4F5559;
  font-weight: 600;
  opacity: 0;
  margin-bottom: 15px;
}
.loader-pdf-wall{
  display: none;
}
.loader-pdf-wall-loading {
  display: block;
}

/* SPINNER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  pointer-events: none;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  opacity: 0;
}
.spinner-loading{
  opacity: 1;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #FE9C54;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>