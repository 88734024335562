<template>
  <div class="home">
    <Header></Header>
    <div class="home-master-container">
      <AdminMenu></AdminMenu>
      <div class="container home-container">

            <div class="row h-100">
              <div class="col-sm admin-content align-self-start">
                <div class="admin-sections-header-text" v-if="currentAdminTab==0">Benvingut, Admin</div>
                <AdminUsers v-if="currentAdminTab==1"></AdminUsers>
                <AdminNewUser v-if="currentAdminTab==2"></AdminNewUser>
                <AdminFinques v-if="currentAdminTab==3"></AdminFinques>
                <AdminNewFinca v-if="currentAdminTab==4"></AdminNewFinca>
              </div>
            </div>  

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import AdminMenu from '@/components/AdminMenu.vue'
import AdminUsers from '@/components/AdminUsers.vue'
import AdminNewUser from '@/components/AdminNewUser.vue'
import AdminFinques from '@/components/AdminFinques.vue'
import AdminNewFinca from '@/components/AdminNewFinca.vue'

export default { 
  name: 'Admin',
  components: {
    Header,
    AdminMenu,
    AdminUsers,
    AdminNewUser,
    AdminFinques,
    AdminNewFinca
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentAdminTab() {
      return this.$store.state.admin.currentTab;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    if (this.currentUser.user.name != 'admin') {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.admin-content {
  padding: 20px;
  background-color: rgb(238, 238, 238);
  margin: 10px;
  height: calc(100% - 30px);
  border-radius: 15px;
}
</style>
