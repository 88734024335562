<template>
    <div class="">
        <div class="admin-sections-header-text">Finques</div>

        <table class="custom-table">
            <tr>
                <th>Codi</th>
                <th>Nom</th>
                <th class="action-column">Arxius</th>
                <th class="action-column">Usuaris</th>
                <th class="action-column">Editar</th>
                <th class="action-column">Esborrar</th>
            </tr>
            <tr v-for="finca in finques" :key="finca.id">
                <td>{{ finca.fincaid }}</td>
                <td>{{ finca.fincaname }}</td>
                <td class="action-column"><img :src="require('@/assets/img/iconfiles.png')" alt="Finca's files" class="table-icon" @click="fincaFilesModal(finca.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/iconuseredit.png')" alt="Finca's users" class="table-icon" @click="fincaUsersModal(finca.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/iconedit.png')" alt="Edit finca" class="table-icon" @click="editFincaModal(finca.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icondelete.png')" alt="delete finca" class="table-icon" @click="confirmDeleteModal(finca.id)"></td>
            </tr>
        </table>

        <!-- Finques Users Modal -->
        <div class="admin-modal" v-if="fincaUsers"><AdminFincaUsers :fincaUsers="fincaUsers"></AdminFincaUsers></div>

        <!-- Finques Files Modal -->
        <div class="admin-modal" v-if="fincaFiles"><AdminFincaFiles :finca="fincaFiles"></AdminFincaFiles></div>

        <!-- Edit Finca Modal -->
        <div class="admin-modal" v-if="fincaToEdit"><AdminNewFinca :editFinca="fincaToEdit"></AdminNewFinca></div>

        <!-- Delete Finca Modal -->
        <div class="admin-modal" v-if="fincaToDelete">
            <div class="admin-sections-header-text">Esborrar finca</div>
            <div v-if="!fincaDeletedOk">
                Esborrar aquesta finca?
                <div class="buttons-container">
                    <div class="custom-button button-gray" @click="closeDeleteFinca()">Cancel·lar</div>
                    <div class="custom-button button-green" @click="deleteFinca()">Esborrar finca</div>
                </div>
            </div>
            <div v-else>
                Finca esborrada amb exit
                <div class="buttons-container">
                    <div class="custom-button button-green" @click="closeDeleteFinca()">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, post } from '@/services/api.js'
import AdminNewFinca from '@/components/AdminNewFinca.vue'
import AdminFincaUsers from '@/components/AdminFincaUsers.vue'
import AdminFincaFiles from '@/components/AdminFincaFiles.vue'

export default {
    name: 'Adminfincas',
    components: {
        AdminNewFinca,
        AdminFincaUsers,
        AdminFincaFiles
    },
    data() {
      return {
        finques: [],
        fincaToEdit: null,
        fincaToDelete: null,
        fincaDeletedOk: false,
        fincaUsers: null,      
        fincaFiles: null,
      }
    },
    mounted () {
        this.getFinques()
    },
    methods: {
        getFinques() {
            get('finques')
            .then(response => {
                this.finques = response.finques
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('finca')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        editFincaModal(fincaId) {
            let url = 'finques/' + fincaId
            get(url)
            .then(response => {
                this.fincaToEdit = response.finca[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('finca')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeEditFinca(){
            this.fincaToEdit = null
            this.getFinques()
        },
        confirmDeleteModal(fincaId){
            this.fincaToDelete = fincaId
        },
        deleteFinca(){
            let url = 'finques/' + this.fincaToDelete + '/delete'
            post(url, null)
            .then(response => {
                this.fincaDeletedOk = true
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('finca')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeDeleteFinca() {
            this.fincaToDelete = null
            this.fincaDeletedOk = false
            this.getFinques()
        },
        fincaUsersModal(fincaId) {
            let url = 'finques/' + fincaId
            get(url)
            .then(response => {
                this.fincaUsers = response.finca[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('finca')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeFincaUsers() {
            this.fincaUsers= null
        },
        fincaFilesModal(fincaId) {
            let url = 'finques/' + fincaId
            get(url)
            .then(response => {
                this.fincaFiles = response.finca[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('finca')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeFincaFiles() {
            this.fincaFiles= null
        },
    }
}
</script>
<style scoped>

</style>