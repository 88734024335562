<template>
    <div class="">
        <div v-if="editFinca" class="admin-sections-header-text">Editar finca</div>
        <div v-else class="admin-sections-header-text">Nova finca</div>
        <div v-if="!saveOk">
            <label for="fincaid">Codi</label>
            <input type="text" name="fincaid" id="fincaid" v-model="newFinca.fincaid">
            <label for="name">Nom</label>
            <input type="text" name="name" id="name" v-model="newFinca.fincaname">
            <div v-if="formError" class="error-container">hi ha camps obligatoris sense omplir</div>
            <div v-else style="height: 20px;"></div>
            <div class="buttons-container">
                <div v-if="editFinca" class="custom-button button-gray" @click="closeModal()">Cancel·lar</div>
                <div v-if="editFinca" class="custom-button button-green" @click="saveFinca()">Actualitzar</div>
                <div v-else class="custom-button button-green" @click="saveFinca()">Crear finca</div>
            </div>
        </div>
        <div v-else>
            <div v-if="editFinca">Finca actualitzada</div>
            <div v-else>Nova finca creada</div>
            <div class="buttons-container">
                <div v-if="editFinca" class="custom-button button-green" @click="closeModal()">OK</div>
                <div v-else class="custom-button button-green" @click="goToFincas()">Tornar a finques</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { post } from '@/services/api.js'

export default {
    name: 'AdminNewFinca',
    props: {
        editFinca: ''
    },        
    data() {
        return {
            newFinca: {
                fincaid: '',
                fincaname: ''
            },
            formError: false,
            saveOk: false
        }
    },
    mounted() {
        if(this.editFinca) this.loadFinca()
    },
    methods: {
        loadFinca() {
            this.newFinca.fincaid = this.editFinca.fincaid
            this.newFinca.fincaname = this.editFinca.fincaname
        },
        closeModal() {
            this.$parent.closeEditFinca()
        },
        goToFincas(){
            this.$store.dispatch('setAdminTab', 3)
        },
        saveFinca() {
            if(this.newFinca.fincaid == '' || this.newFinca.name == '') {
                this.formError = true
            }
            else {
                let objectToSend = {}
                objectToSend.fincaid =  this.newFinca.fincaid
                objectToSend.fincaname =  this.newFinca.fincaname

                let url = 'finques'
                if(this.editFinca) {
                    url += '/' + this.editFinca.id
                }
                post(url, objectToSend)
                .then(response => {
                    this.saveOk = true
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('finca')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                })
            }
        }
        /*changeTab(tab) {
            this.$store.dispatch('setAdminTab', tab)          
        },*/
    }
}
</script>
<style scoped>

</style>