import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: {
      isAdminPanel: false,
      currentTab: 0,
    },
    finques: {
      userFinques: [],
      selectedFinca: null
    }
  },
  mutations: {
    toggleAdminPanel(state, adminPanel) {
      state.admin.isAdminPanel = adminPanel
    },
    setAdminCurrentTab(state, tab) {
      state.admin.currentTab = tab
    },
    setUserFinques(state, userFinques ){
      state.finques.userFinques = userFinques
    },
    setSelectedFinca(state, selectedFinca ){
      state.finques.selectedFinca = selectedFinca
    }
  },
  actions: {
    setAdminPanel({ commit }, adminPanel) {
      commit('toggleAdminPanel', adminPanel)
    },
    setAdminTab({ commit }, tab) {
      commit('setAdminCurrentTab', tab)
    },
    changeUserFinques({ commit }, userFinques) { 
      commit('setUserFinques', userFinques)
    },
    changeSelectedFinca({ commit }, selectedFinca) { 
      commit('setSelectedFinca', selectedFinca)
    }
  },
  modules: {
    auth
  }
})
