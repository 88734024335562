<template>
    <div class="">
        <div class="admin-sections-header-text">Usuaris de {{ fincaUsers.fincaname }}</div>
            <table class="custom-table">
                <tr>
                    <th>Codi usuari</th>
                    <th>Nom usuari</th>
                    <th class="action-column"></th>
                </tr>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.userid }}</td>
                    <td>{{ user.name }}</td>
                    <td class="action-column"><img :src="require('@/assets/img/icondelete.png')" alt="delete user" class="table-icon" @click="unbind(user.id)"></td>
                </tr>
            </table>
            <div class="separator"></div>
            <label for="selectedUser">Afegir un usuari</label>
            <div class="row">
                <div class="col-sm-10">
                    <select v-model="selectedUser" name="selectedUser">
                        <option v-for="user in allUsers" :key="user.id" :value="user.id"> {{ user.userid }} - {{ user.name }}</option>
                    </select>
                </div>
                <div class="col-sm-2">
                    <div class="custom-button button-gray" @click="bind()" style="padding: 5px; width: 40px; height: 40px;">
                        <img :src="require('@/assets/img/iconnew.png')" alt="add user" class="table-icon" style="width: 10px;">
                    </div>
                </div>
            </div>
            <div class="separator"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Tancar</div>
            </div>
    </div>
    
</template>
<script>
import { get, post } from '@/services/api.js'

export default {
    name: 'AdminFincaUsers',
    props: {
        fincaUsers: ''
    },        
    data() {
        return {
            users: [],
            allUsers: [],
            selectedUser: ''
        }
    },
    mounted() {
        this.getFincaUsers()
        this.getAllUsers()
    },
    methods: {
        getFincaUsers(){
            let url = 'userfinques/finca/' + this.fincaUsers.id
            get(url)
            .then(response => {
                this.users = response.users
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        getAllUsers() {
            let url = 'users'
            get(url)
            .then(response => {
                this.allUsers = response.users
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        bind() {
            let url = 'userfinques/' + this.selectedUser + '/' + this.fincaUsers.id
            post(url, null)
            .then(response => {
                this.getFincaUsers()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        unbind(userid) {
            let url = 'userfinques/' + userid + '/' + this.fincaUsers.id + '/delete'
            post(url, null)
            .then(response => {
                this.getFincaUsers()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeModal() {
            this.$parent.closeFincaUsers()
        },
    }
}
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #cccccc;
    }
</style>