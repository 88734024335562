<template>
    <div class="">
        <div v-if="editUser" class="admin-sections-header-text">Editar usuari</div>
        <div v-else class="admin-sections-header-text">Nou usuari</div>
        <div v-if="!saveOk">
            <label for="userid">Codi</label>
            <input type="text" name="userid" id="userid" v-model="newUser.userid">
            <label for="name">Nom</label>
            <input type="text" name="name" id="name" v-model="newUser.name">
            <label for="email">Email</label>
            <input type="text" name="email" id="email" v-model="newUser.email">
            <label for="password">Contranenya</label>
            <input type="password" name="password" id="password" v-model="newUser.password">
            <div v-if="formError" class="error-container">hi ha camps obligatoris sense omplir</div>
            <div v-else style="height: 20px;"></div>
            <div class="buttons-container">
                <div v-if="editUser" class="custom-button button-gray" @click="closeModal()">Cancel·lar</div>
                <div v-if="editUser" class="custom-button button-green" @click="saveUser()">Actualitzar</div>
                <div v-else class="custom-button button-green" @click="saveUser()">Crear usuari</div>
            </div>
        </div>
        <div v-else>
            <div v-if="editUser">Usuari actualitzat</div>
            <div v-else>Nou usuari creat</div>
            <div class="buttons-container">
                <div v-if="editUser" class="custom-button button-green" @click="closeModal()">OK</div>
                <div v-else class="custom-button button-green" @click="goToUsers()">Tornar a usuaris</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { post } from '@/services/api.js'

export default {
    name: 'Adminnewuser',
    /*computed: {
        currentAdminTab() {
            return this.$store.state.admin.currentTab;
        },
    },*/
    props: {
        editUser: ''
    },        
    data() {
        return {
            newUser: {
                userid: '',
                name: '',
                email: '',
                password: ''
            },
            formError: false,
            saveOk: false
        }
    },
    mounted() {
        if(this.editUser) this.loadUser()
    },
    methods: {
        loadUser() {
            this.newUser.userid = this.editUser.userid
            this.newUser.name = this.editUser.name
            this.newUser.email = this.editUser.email
            this.newUser.password = '********'
        },
        closeModal() {
            this.$parent.closeEditUser()
        },
        goToUsers(){
            this.$store.dispatch('setAdminTab', 1) 
        },
        saveUser() {
            if(this.newUser.userid == '' || this.newUser.name == '' || this.newUser.password == '') {
                this.formError = true
            }
            else {
                let objectToSend = {}
                objectToSend.userid =  this.newUser.userid
                objectToSend.name =  this.newUser.name
                objectToSend.email =  this.newUser.email
                if (this.newUser.password != '********') {
                    objectToSend.password =  this.newUser.password
                }

                let url = 'users'
                if(this.editUser) {
                    url += '/' + this.editUser.id
                }
                post(url, objectToSend)
                .then(response => {
                    this.saveOk = true
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('user')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                })
            }
        }
        /*changeTab(tab) {
            this.$store.dispatch('setAdminTab', tab)          
        },*/
    }
}
</script>
<style scoped>

</style>