import axios from 'axios'
import authHeader from './auth-header'
import store from '../store/index'
import router from '../router/index'

//const server_path = 'http://localhost/public/api/'
const server_path = 'https://arrelsapi.geo-memorial.cat/api/'

export function login(name,password){
    const user_data = {name, password}
    return axios       
        .post(server_path + 'auth/login', user_data)
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        })
}

export function logout(){
    localStorage.removeItem('user');
}

export function get(url){
    return axios       
        .get(server_path + url, { headers: authHeader() })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            if(error.response.data.error.includes("token")){
                localStorage.removeItem('user')
                store.dispatch('auth/logout')
                router.push('/login')
            }
        })    
}

export function post(url, object){
    return axios       
        .post(server_path + url, object, { headers: authHeader() })
        .then(response => {
            return response.data;
        })     
}

export function uploadFile(url, file){
    let headers = authHeader();
    const formData = new FormData();
    formData.append('File',file)
    
    return axios       
        .post(server_path + url, formData, { 
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": headers.Authorization 
            }
        })
        .then(response => {
            return response.data;
        })    
}


export function getPdf(url){
    return axios       
        //.get('http://s23.q4cdn.com/479936946/files/doc_downloads/test.pdf', {responseType: "blob"})
        .get(server_path + url, { headers: authHeader(), responseType: "blob" })
        .then(response => {
            //console.log("Success", response);
            const blob = new Blob([response.data]);
            const objectUrl = URL.createObjectURL(blob);
            //console.log(objectUrl);
            return objectUrl;
        })
        .catch(error => {
            if(error.response.data.error.includes("token")){
                localStorage.removeItem('user')
                store.dispatch('auth/logout')
                router.push('/login')
            }
        })    
}
